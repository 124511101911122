import { v4 as uuidv4 } from "uuid";
import actions from "./actions";

const workExperience = {
  id: uuidv4(),
  company: "",
  jobTitle: "",
  date: "",
  descriptions: [],
  technical_environment: [],
  description_editor: "",
};
const education = {
  id: uuidv4(),
  school: "",
  degree: "",
  date: "",
};
const certification = {
  id: uuidv4(),
  name: "",
  date: "",
};

const initState = {
  type: "CONTRACT",
  language: "FR",
  GuardFormValues: { CandidateID: "" },
  step: 0,
  mqlog_id: "",
  mqfile_name: "",
  Gard_page_Input_error: null,
  resume_file: null,
  ResumeFormValues: {
    summary: "",
    workExperiences: [workExperience],
    educations: [education],
    skills: [],
    certifications: [certification],
  },
  ResumeFormVisibility: {
    summary: true,
    workExperiences: true,
    educations: true,
    skills: true,
    certifications: true,
  },
};

export default function mqCvGeneratorReducer(state = initState, action) {
  if (action.type === actions.SET_TYPE) {
    return {
      ...state,
      type: action.payload,
    };
  }

  if (action.type === actions.SET_LANGUAGE) {
    return {
      ...state,
      language: action.payload,
    };
  }

  if (action.type === actions.SET_GUARD_FORM_VALUES) {
    return {
      ...state,
      GuardFormValues: {
        ...state.GuardFormValues,
        ...action.payload,
      },
    };
  }
  if (action.type === actions.SET_RESUME_FORM_VALUES) {
    return {
      ...state,
      ResumeFormValues: {
        ...state.ResumeFormValues,
        ...action.payload,
      },
    };
  }
  if (action.type === actions.SET_RESUME_FILE) {
    return { ...state, resume_file: action.payload };
  }
  if (action.type === actions.SET_RESUME_FORM_VISIBILITY) {
    return {
      ...state,
      ResumeFormVisibility: {
        ...state.ResumeFormVisibility,
        ...action.payload,
      },
    };
  }
  if (action.type === actions.CHANGE_STEP) {
    return {
      ...state,
      step: action.payload,
    };
  }
  if (action.type === actions.SET_FILE_NAME) {
    return {
      ...state,
      mqfile_name: action.payload,
    };
  }
  if (action.type === actions.SET_LOG_ID) {
    return {
      ...state,
      mqlog_id: action.payload,
    };
  }
  if (action.type === actions.EMPTY_FIELDS) {
    return {
      ...state,
      ResumeFormValues: {
        summary: "",
        workExperiences: [],
        educations: [],
        skills: [],
        certifications: [],
      },
      GuardFormValues: {},
      type: "CONTRACT",
    };
  }
  if (action.type === actions.RESTORE_CACHE) {
    return { ...action.payload, step: 3 };
  }
  if (action.type === actions.EMPTY_GARDE_PAGE) {
    return { ...state, GuardFormValues: {} };
  }
  return state;
}
