const actions = {
  SET_TYPE: "SET_TYPE",
  SET_LANGUAGE: "SET_LANGUAGE",
  SET_GUARD_FORM_VALUES: "SET_GUARD_FORM_VALUES",
  CHANGE_STEP: "CHANGE_STEP",
  SET_RESUME_FORM_VALUES : "SET_RESUME_FORM_VALUES",
  SET_RESUME_FORM_VISIBILITY : "SET_RESUME_FORM_VISIBILITY",
  SET_FILE_NAME : "SET_FILE_NAME",
  SET_LOG_ID : "SET_LOG_ID",
  EMPTY_FIELDS:"EMPTY_FIELDS",
  RESTORE_CACHE:"RESTORE_CACHE",
  SET_RESUME_FILE:"SET_RESUME_FILE",
  EMPTY_GARDE_PAGE:"EMPTY_GARDE_PAGE",
  settype: (payload) => ({
    type: actions.SET_TYPE,
    payload,
  }),
  setlanguage: (payload) => ({
    type: actions.SET_LANGUAGE,
    payload,
  }),
  setGuardFormValues: (payload) => ({
    type: actions.SET_GUARD_FORM_VALUES,
    payload,
  }),
  setResumeFiles:(payload)=>({
    type:actions.SET_RESUME_FILE,
    payload
  }),
  setResumeFormValues: (payload) => ({
    type: actions.SET_RESUME_FORM_VALUES,
    payload,
  }),
  setResumeFormVisibility: (payload) => ({
    type: actions.SET_RESUME_FORM_VISIBILITY,
    payload,
  }),
  changeStep: (payload) => ({
    type: actions.CHANGE_STEP,
    payload,
  }),
  setFileName:(payload)=>({
   type : actions.SET_FILE_NAME,
   payload
  }),
  setLogId:(payload)=>({
    type : actions.SET_LOG_ID,
    payload
   }),
   empty_fields:()=>({
    type:actions.EMPTY_FIELDS
   }),
   restore_cache:(payload)=>({
    type:actions.RESTORE_CACHE,
    payload
   }),
   emptyGardePage:(payload)=>({
    type:actions.EMPTY_GARDE_PAGE,
    payload
   })
};

export default actions;
